<template>
  <div>
    <page-title title="Poducts" :breadcrumbs-items="breadcrumbsItems"></page-title>
    <v-row class="mb-2">
      <v-col>
        <p>PT Beton Perkasa Wijakasa is the market leader in formwork, scaffolding, and engineering for building, civil and industrial construction projects.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title>Filters</v-card-title>

          <div class="text-center py-6" v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
              ></v-progress-circular>
          </div>

    
          <v-list flat v-if="!loading">
            <v-subheader class="py-0 my-0" style="height: 28px"
              >Categories
              <v-spacer></v-spacer>
              <a href="javascript:;" @click="selectAllCategories" v-if="!selectedAllCategories">Select All |</a>
              <a href="javascript:;" @click="clearAllCategories">Clear</a>
            </v-subheader>

            <v-list-item-group v-model="selectedCategories" multiple active-class="">
              <v-list-item v-for="(item, i) in categories" :key="i" style="min-height: 28px">
                <template v-slot:default="{ active }">
                  <v-list-item-action class="py-0 my-0">
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content class="py-0 my-0">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <!-- <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle> -->
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <div class="text-center py-6" v-if="loading2">
            <v-progress-circular
              indeterminate
              color="primary"
              ></v-progress-circular>
          </div>

          <v-list flat v-if="!loading2">
            <v-subheader class="py-0 my-0" style="height: 28px"
              >Brands
              <v-spacer></v-spacer>
              <a href="javascript:;" @click="selectAllBrands" v-if="!selectedAllBrands">Select All |</a>
              <a href="javascript:;" @click="clearAllBrands">Clear</a>
            </v-subheader>

            <v-list-item-group v-model="selectedBrands" multiple active-class="">
              <v-list-item v-for="(item, i) in brands" :key="i" style="min-height: 28px">
                <template v-slot:default="{ active }">
                  <v-list-item-action class="py-0 my-0">
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content class="py-0 my-0">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <!-- <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle> -->
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col style="height:100vh;overflow-y:auto" cols="12" md="9">
        <div class="text-center py-6" v-if="loading3">
          <v-progress-circular
            indeterminate
            color="primary"
            ></v-progress-circular>
        </div>
        
        <div v-if="!loading3">
          <div v-for="(item, i) in productCategoriesFiltered" :key="i">
            <v-card elevation="3" dark>
              <v-img
                gradient="to top left, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                height="200px"
                class="white--text align-end"
                :src="'https://apibasicweb.betonperkasa.com/category-product/' + item.category.thumbnail"
              >
              <v-card-title class="text-md-h2"><span class="text--white">{{ item.category.name }}</span></v-card-title>
              <v-card-text class=" text-subtitle-1">
                {{ item.category.description }}
              </v-card-text>
              </v-img>
            </v-card>

            <v-row class="mt-3 mb-6">
              <v-col  v-for="(product, i) in item.products" :key="i" class="d-flex child-flex" md="4" cols="12">
                <v-card outlined elevation="3" @click="viewProduct(product)">
                  <v-img
                    gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    height="200px"
                    class="white--text align-end"
                    :src="'https://apibasicweb.betonperkasa.com/photo/product/' + product.PhotoProducts[0].path"
                  >
                  </v-img>
                  <v-card-title style="min-height:80px;font-size:16px"><a href="#" @click="viewProduct(product)">{{ product.name }}</a></v-card-title>
                  <v-card-subtitle>{{ product.Brand.name }}</v-card-subtitle>
                  <!-- <v-card-actions class="pa-1">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" block title="View">
                      <v-icon>mdi-eye</v-icon> &nbsp;&nbsp;&nbsp; View
                    </v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="800" scrollable>
      <v-card>
        <v-toolbar elevation="1">
          <v-toolbar-title>
            <v-icon>mdi-cube-scan</v-icon> {{ selectedProduct.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="(dialog = !dialog)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <!-- <v-card-title>{{ selectedProduct.name }}</v-card-title> -->
        <v-card-text>
        <v-carousel height="400">
          <v-carousel-item
            v-for="(item,i) in selectedProduct.PhotoProducts"
            :key="i"
            :src="'https://apibasicweb.betonperkasa.com/photo/product/' + item.path"
            
          ></v-carousel-item>
        </v-carousel>
          <div v-html="selectedProduct.description" class="mt-4"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="(dialog = !dialog)">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},

  data: () => ({
    loading: false,
    loading2: false,
    loading3: false,
    categories: [],
    selectedCategories: [],
    brands: [],
    selectedBrands: [],
    products: [],
    // productsFiltered: [],
    selectedProduct: {},
    dialog: false,
    breadcrumbsItems: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/',
      },
      {
        text: 'Products',
        disabled: true,
        href: '#',
      },
    ],
  }),

  computed: {
    selectedAllCategories() {
      if (this.categories.length === this.selectedCategories.length) return true
      return false
    },
    selectedAllBrands() {
      if (this.brands.length === this.selectedBrands.length) return true
      return false
    },
    productCategoriesFiltered(){
      var filteredData = [];

      if(this.selectedCategories.length > 0){
        this.selectedCategories.forEach(idx => {
          this.categories.forEach(category => {
            if(idx == this.categories.indexOf(category)){
              var products = []
              this.products.forEach(product => {
                if(product.category_product_id==category.id){
                  products.push(product)
                }
              })

              var item = {
                category: category,
                products: products
              }
              filteredData.push(item)
            }
          })
        })
      }else{
        this.categories.forEach(category => {
          var products = []
          this.products.forEach(product => {
            if(product.category_product_id==category.id){
              products.push(product)
            }
          })

          var item = {
            category: category,
            products: products
          }
          filteredData.push(item)
        })
      }

      var filteredData2 = [];

      if(this.selectedBrands.length > 0){
        filteredData.forEach(data => {
          var _prods = [];
          this.selectedBrands.forEach(idx => {
            var brand = this.brands[idx];
            data.products.forEach(product =>{
              if(product.brand_id == brand.id){
                _prods.push(product)
              }
            })
          })

          if(_prods.length > 0){
            var item = {
              category: data.category,
              products: _prods
            }
            filteredData2.push(item)
          }

        })
      }else{
        filteredData2 = filteredData
      }
      return filteredData2
    },
  },

  methods: {
    async getCategories() {
      this.loading = true
      let config = {
        url: 'https://apibasicweb.betonperkasa.com/category-product',
        params: {
          sort: 'ASC',
        },
        cacheConfig: true,
      }
      await this.$axios(config).then(response => {
        var resData = response.data

        this.categories = resData.data

        this.loading = false
      })
    },
    selectAllCategories() {
      this.selectedCategories = []
      this.categories.forEach(item => {
        this.selectedCategories.push(this.categories.indexOf(item))
      })
    },
    clearAllCategories() {
      this.selectedCategories = []
    },
    async getBrands() {
      
      this.loading2 = true

      let config = {
        url: 'https://apibasicweb.betonperkasa.com/brand',
        cacheConfig: true,
      }
      await this.$axios(config).then(response => {
        var resData = response.data

        this.brands = resData.data

        this.loading2 = false
      })
    },
    selectAllBrands() {
      // console.log(this.selectedBrands)
      this.selectedBrands = []
      this.brands.forEach(item => {
        this.selectedBrands.push(this.brands.indexOf(item))
      })
      // console.log(this.selectedBrands)
    },
    clearAllBrands() {
      this.selectedBrands = []
    },
    async getProducts() {
      this.loading3 = true

      let config = {
        url: 'https://apibasicweb.betonperkasa.com/product',
        cacheConfig: true,
      }
      await this.$axios(config).then(response => {
        var resData = response.data

        this.products = resData.data

        this.loading3 = false
      })
    },

    viewProduct(productData){
      this.selectedProduct = productData
      this.dialog = true
    }
  },

  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push('/login')
    }
  },
  mounted() {
    this.$store.state.showBackButton = false
    this.setTitle('Products')

    this.getCategories()
    this.getBrands()
    this.getProducts()
  },
}
</script>
